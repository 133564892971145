<script>
  import Input from "$lib/admin/components/Input.svelte";
  import Group from "$lib/admin/components/Group.svelte";
  import Button from "$lib/main/components/Button.svelte";
  import Heading from '$lib/admin/components/blocks/helpers/Heading.svelte';
  import Block from '$lib/admin/components/blocks/helpers/Block.svelte';
  import Icons from "$lib/main/components/Icons.svelte";
  import { flip } from 'svelte/animate';
  import { quintOut } from 'svelte/easing';
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher()

  // props.listType: select - choose type of list to show
  //  options: ul, ol
  // props.listStyle: select - choose the styling of the discs
  //  options: default, pros, cons, gift
  // props.listItems: array - holds all the list items

  // TODO:
  // - bug: animate interferes with input (triggered on input as well)
  // - bug: can drop in input fields outside list scope

  export let props = null;
  export let adminView = false;
  let hoveringItem = null;
  let dragCounter = 0;

  function addListItem() {
    if(!Array.isArray(props.listItems)) props.listItems = []
    props.listItems.push("")
    props = props
  }

  function removeListItem(index) {
    props.listItems.splice(index, 1)
    props = props
  }

  function handleDragStart(e, index) {
    e.dataTransfer.setData("text/plain", index)
  }
</script>

{#if adminView}
  <Group colspan="4-8" addClass="form-item">
    <Input
      label="List type"
      type="select" bind:value={props.listType} on:change>
      <option value="ul">Unordered list</option>
      <option value="ol">Ordered list</option>
    </Input>
    <Input
      label="List style"
      type="select" bind:value={props.listStyle} on:change>
      <option value="">Default</option>
      <option value="pros">Pros</option>
      <option value="cons">Cons</option>
      <option value="gift">Gift</option>
      <!-- <option value="custom">Custom</option> -->
    </Input>
  </Group>
  {#if props.listItems}
    <div class="list-items draggable">
      {#each props.listItems as item, index (index)}
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          animate:flip={{ duration: 200, easing: quintOut }}
          class={`list-item ${index + 1}`}
          class:hovering={hoveringItem === index}

          on:dragenter={() => {dragCounter++; hoveringItem = index}}
          on:dragleave={() => {dragCounter--; if(dragCounter === 0) {hoveringItem = null}}}
          on:dragover={(event) => (
            parseInt(event.dataTransfer.getData("text/plain")) == index)
            ? null
            : event.preventDefault()
          }
          on:drop={(event) => {
            event.preventDefault();
            props.listItems.splice(index, 0, props.listItems.splice(parseInt(event.dataTransfer.getData("text/plain")), 1)[0]);
            dispatch('change', { bubbles: true })
          }}
          >
          <div
            class="grab" 
            draggable="true"
            on:dragstart={(event) => handleDragStart(event, index)}
            on:dragend={() => hoveringItem = null}>
            <Icons name="grid" width="12px" height="12px" strokeWidth="1.5" />
          </div>
          <Input type="text" bind:value={item} on:keyup />
          <Button
            addClass="secondary"
            onlyIcon
            icon="trash-2"
            on:click={() => removeListItem(index)} />
        </div>
      {/each}
    </div>
  {/if}
  <Button addClass="primary" icon="plus-square" on:click={() => addListItem()}>Add item</Button>
{:else}
  <Block
    blockClass="block-lists"
    gridColumn="g-col-12 g-start-1 g-col-md-10 g-start-md-2"
    {props}>
    <Heading {props} />

    {#if props.listType}
      <svelte:element
        this={props.listType}
        class={props.listStyle}>
        {#if props.listItems}
          {#each props.listItems as item}
            <li>{@html item}</li>
          {/each}
        {/if}
      </svelte:element>
    {/if}
  </Block>
{/if}