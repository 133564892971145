<script>
  import { project } from "$lib/services/store";
  import Icons from "$lib/main/components/Icons.svelte";
  import FeedbackForm from "$lib/admin/components/FeedbackForm.svelte";
  import Translate from "$lib/admin/components/blocks/helpers/Translate.svelte";

  export let address = null;
  export let admin = false;

  let currentYear = new Date().getFullYear()
</script>


{#if $project.menu?.footerMenu?.type == 'big' && admin == false}
  <footer class="style-b">
    <div class="block block-lists">
      <div class="container">
        <div class="grid">
          {#if $project.menu.footerMenu.row1}
            <div class="g-col-12 g-col-sm-6 g-col-lg-3">
              <h4>{$project.menu.footerMenu.row1.title}</h4>
              <ul>
                {#each $project.menu.footerMenu.row1.menu as menuItem}
                  <li><a href={menuItem.path}>{menuItem.name}</a></li>
                {/each}
              </ul>
            </div>
          {/if}
          {#if $project.menu.footerMenu.row2}
            <div class="g-col-12 g-col-sm-6 g-col-lg-3">
              <h4>{$project.menu.footerMenu.row2.title}</h4>
              <ul>
                {#each $project.menu.footerMenu.row2.menu as menuItem}
                  <li><a href={menuItem.path}>{menuItem.name}</a></li>
                {/each}
              </ul>
            </div>
          {/if}
          {#if $project.menu.footerMenu.about}
            <div class="g-col-12 g-col-sm-6 g-col-lg-3">
              <h4><Translate text="About" /> {$project.settings.domain}</h4>
              <ul>
                {#each $project.menu.footerMenu.about.menu as menuItem}
                  <li><a href={menuItem.path}>{menuItem.name}</a></li>
                {/each}
              </ul>
            </div>
          {/if}
          <div class="g-col-12 g-col-sm-6 g-col-lg-3">
            <h4><Translate text="Contact us" /></h4>
            <ul>
              <li>
                <!-- <FeedbackForm type="button" isAdminFeedback={false}><Translate text="Contact" /></FeedbackForm> -->
                <a href="/kontakta-oss">Kontakt</a> / <a href="mailto:mail@casinofeber.se">E-post</a>
              </li>
              {#if address}
                <li>{address.name}</li>
                <li>{address.street}</li>
                <li>{address.region}, {address.zip}</li>
                <li>{address.country}</li>
              {/if}
            </ul>
          </div>

        </div>
      </div>
    </div>

    {#if $project.menu.footerMenu.gambleAware}
      <div class="block block-images-gamble-aware">
        <div class="container">
          <div class="grid">
            <div class="g-col-12">
              {@html $project.menu.footerMenu.gambleAware}
            </div>
          </div>
        </div>
      </div>
    {/if}

    {#if $project.menu.footerMenu.socials}
      <div class="block block-socials">
        <div class="container">
          <div class="grid">
            <div class="g-col-12">
              <div class="social-row">
                {#each $project.menu.footerMenu.socials as social}
                  <a class={ "_" + social.platform } href={social.url} target="_blank" rel="nofollow noreferrer noopener"><Icons name={social.platform} strokeWidth="1.5" width="24px" height="24px" /></a>
                {/each}
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}

    {#if $project.menu.footerMenu.links}
      <div class="block block-links">
        <div class="container">
          <div class="grid">
            <div class="g-col-12">
              <div class="links-row">
                {#each $project.menu.footerMenu.links as link}
                  <a href={link.url} target="_blank" rel="noreferrer">
                    <img
                      loading="lazy"
                      alt={link.region}
                      src={$project.data?.imageFolders.regions + link.region + ".svg"} />
                  </a>
                {/each}
              </div>
            </div>
          </div>
        </div>
      </div>
    {/if}

    <div class="block block-content">
      <div class="container">
        <div class="grid">
          <div class="g-col-12">
            {#if $project.menu.footerMenu.tc}
              <p>{$project.menu.footerMenu.tc}</p>
            {/if}

            <p>Copyright © {currentYear} {$project?.name}</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
{:else}
  <footer class="style-a">
    <div class="bottom-footer">Copyright © {currentYear} {admin ? "Raketech CMS" : $project?.name}</div>
  </footer>
{/if}